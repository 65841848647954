@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes bounce { 
  from {transform: translateY(0);}
  50% {transform: translateY(-5px);}
  to {
    transform: translateY(0);
  }
} 

.big-spin {
  animation: spin 1.5s 1;
}
.bounce-inf {
  animation: bounce 1s infinite;
}

.nav-spin {
  animation: spin 1s 1;
}

.work-bounce {
  animation: bounce 0.5s 2;
}

.add-wobble {
  animation: small-wobble 1s 1;
}

@keyframes small-wobble {
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes move-out {
  from {
    margin-left: -100px;
  }
  to {
    margin-left: 20px
  }
}

.move-out {
  animation: move-out 0.5s 1;
}

@keyframes move-back {
  from {
    margin-left: 20px;
  }
  to {
    margin-left: 100px
  }
}
.move-back {
  animation: move-back 0.5s 1;
}