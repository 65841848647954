@import "./helpers";

.nav-container {
  @include flexbox(row, space-between, center);
  height: 85px;
  padding-bottom: 2px;
  background-color: rgb(19, 19, 19);
  color: white;
  opacity: 0.9;
  padding-left: 55px;
  padding-right: 55px;
  box-shadow: 0 0 4px 4px rgb(199, 199, 199);
  color-interpolation-filters: none;
}

.logo {
  max-height: 55px;
  max-width: 55px;
}
.nav-logo {
  cursor: pointer;
  transition: all 200ms;

  i {
    transition: all 200ms;
  }
  &:hover {
    i::before,
    i,
    i::after {
      background-image: linear-gradient(111deg, #f2bf26, #b55eab);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.about-me-logo {
  transition: all 200ms;

  i,
  i::before,
  i::after {
    transition: all 200ms;
  }
  &:hover {
    i::before,
    i,
    i::after {
      background-image: linear-gradient(111deg, #f2bf26, #b55eab);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.text-gradient-1 {
  background-image: linear-gradient(111deg, #f2bf26, #b55eab);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-me-logo {
  justify-content: center;
  font-size: 37px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 6px;
}

.selected {
  border-radius: 50%;
  border: 2px solid rgb(177, 177, 177);
  // color: rgb(177, 177, 177) !important;
  height: 60px;
  width: 60px;
  text-align: center;
  // background-image: linear-gradient(111deg, #f2bf26, #b55eab);
  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}
.not-selected {
  border-radius: 50%;
  border: 2px solid rgb(19, 19, 19);

  color: white;
  height: 60px;
  width: 60px;
  text-align: center;
}
.nav-spacer {
  display: none;
}

@media screen and (max-width: 650px) {
  .nav-container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 2;
    height: 75px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-spacer {
    display: block;
    position: absolute;
    height: 75px;
    width: 95vw;
  }
}

@media screen and(min-width: 651px) and (max-width: 849px) {
  .nav-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .nav-spacer {
    display: block;
    // position: absolute;
    height: 75px;
    width: 95vw;
  }
}
