@import './helpers.scss';

#root {
  font-family: $font-stack;
  overflow-x: hidden;
  height: 100vh;
}
.App {
  height: 100%;
}
a {
  color: white;
  color-interpolation-filters: auto;
}

// @media screen and (min-width: 851px) {
//   #root {
//     max-height: 100vh;
//   }
// }